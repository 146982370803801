import axiosInstance from "./common/axios-config";
import axios from "axios"
import {GetAuthItem} from "./auth-construct";

const elasticSearchBaseUrl = "https://search-elastic-sieraglobal-aygrkblr6o5fxiftlxdsmszale.eu-west-2.es.amazonaws.com/";
const searchUrlSuffix = "/_search";
const authUser = [
  [ {item: 14, modifier: 'u'}, 
    {item: 18, modifier: 'l'}], 
  [ {item: 15, modifier: 'u'}, 
    {item: '@', modifier: 'c'},
    {item: 8, modifier: 'l'}], 
  [ {item: 21, modifier: 'u'},
    {item: 15, modifier: 'u'},
    {item: 14, modifier: 'u'}], 
  [ {item: 20, modifier: 'u'},
    {item: 10, modifier:'l'}, 
    {item:2, modifier: 'l'}]
];
const authPass = [
  [ {item: 23, modifier: 'l'},
    {item: 11, modifier: 'u'}],
  [ {item: 11, modifier: 'l'},
    {item: 5, modifier: 'l'},
    {item: "!", modifier: 'c'}],
  [ {item: 2, modifier: 'n'},
    {item: 19, modifier: 'l'},
    {item: 9, modifier: 'l'}],
  [ {item: 6, modifier: 'n'},
    {item: 5, modifier:'u'},
    {item: 23, modifier: 'l'}]
];

export const GetProcessesData = async () => {

  const {data} = await axiosInstance.get(`/api/v1/ImportConfig`);
  const configData = await data;

  const configIds = configData.reduce((result, config) => {
    return [...result, config.id]
  }, []);

  const dataLastRun = await Promise.all(
    configData.map(config =>  {
      const indexName = JSON.parse(config.configJSON).details.elasticSearchIndex;
      const result = axios.get(elasticSearchBaseUrl + indexName + searchUrlSuffix, {
        auth: {
          username: GetAuthItem(authUser[0], authUser[1], authUser[2], authUser[3]),
          password: GetAuthItem(authPass[0], authPass[1], authPass[2], authPass[3])
        },
        params: {
          source: JSON.stringify({
            query: {
              bool: {
                must: [
                  { match: { configId: config.id } },
                  { match: { step: 0 } },
                  { match: { message: "Execution started" } }
                ]
              }
            },
            sort: [
                { created: { order: "desc" }}
            ],
            size: 1
          }),
          source_content_type: 'application/json'
        }
      });

    return result;
  }));

  const dataErrors = await Promise.all(
    configData.map(config => {
      const indexName = JSON.parse(config.configJSON).details.elasticSearchIndex;
      const result = axios.get(elasticSearchBaseUrl + indexName + searchUrlSuffix, {
      auth: {
        username: GetAuthItem(authUser[0], authUser[1], authUser[2], authUser[3]),
        password: GetAuthItem(authPass[0], authPass[1], authPass[2], authPass[3])
      },
        params: {
          source: JSON.stringify({
            query: {
              bool: {
                must: [
                  { match: { configId: config.id } },
                  { match : {  status: 3 } }
                ],
                filter: 
                  {
                    range: {
                      created: {
                        gte: "now-1d"
                      }
                    }
                  }
              }
            }
          }),
          source_content_type: 'application/json'
        }
      });

      return result;
  }));

  const dataFiles = await Promise.all(
    configData.map(config => {
      const indexName = JSON.parse(config.configJSON).details.elasticSearchIndex;
      const result = axios.get(elasticSearchBaseUrl + indexName + searchUrlSuffix, {
      auth: {
        username: GetAuthItem(authUser[0], authUser[1], authUser[2], authUser[3]),
        password: GetAuthItem(authPass[0], authPass[1], authPass[2], authPass[3])
      },
        params: {
          source: JSON.stringify({
            query: {
              bool: {
                must: [
                  { match: { configId: config.id } },
                  { match: { step: 1 } },
                  { match: { status: 1 } },
                ],
                filter: {
                  range: {
                    created: {
                      gte: "now-1d"
                    }
                  }
                }
              }
            }
          }),
          source_content_type: 'application/json'
        }
      });

      return result;
  }));

  let configData2Map = configData.reduce((acc, curr) => {
    acc[curr.id] = curr
    return acc;
  }, {});


  let dataLastRunCombined = dataLastRun.map(d => 
    Object.assign({lastRun: d.data.hits.hits[0] ? d.data.hits.hits[0]._source.created : 'Never run'}, d.data.hits.hits[0] ? configData2Map[d.data.hits.hits[0]._source.configId] : 0)
  );
  
  let dataErrorsCombined = dataErrors.map(d => Object.assign({errors: d.data.hits.total.value}, d.data.hits.hits[0] ? configData2Map[d.data.hits.hits[0]._source.configId] : 0));

  let dataFilesCombined = dataFiles.map(d => Object.assign({files: d.data.hits.total.value}, d.data.hits.hits[0] ? configData2Map[d.data.hits.hits[0]._source.configId] : 0));
  
  let dataLastRunAndErrorsCombined = dataLastRunCombined.map((item, i) => Object.assign({}, item, dataErrorsCombined[i]));
  
  let dataConfigAndElasticSearchComplete = dataLastRunAndErrorsCombined.map((item, i) => Object.assign({}, item, dataFilesCombined[i]));
  
  return dataConfigAndElasticSearchComplete;
}

export const GetProcessDetailData = async ({queryKey}) => {
   const [_key, id] = queryKey;
   
   const {data} = await axiosInstance.get(`/api/v1/ImportConfig/${id}`);

   const indexName = JSON.parse(data.configJSON).details.elasticSearchIndex;
   const searchUrl = elasticSearchBaseUrl + indexName + searchUrlSuffix;

  const dataLoginInfo = await axios.get(searchUrl, {
    auth: {
      username: GetAuthItem(authUser[0], authUser[1], authUser[2], authUser[3]),
      password: GetAuthItem(authPass[0], authPass[1], authPass[2], authPass[3])
    },
    params: {
      source: JSON.stringify({
        query: {
          bool: {
            must: [
              { match: { configId: data.id } },
              { match: { step: 1 } },
              { match: { status: 1 } }
            ]
          }
        },
        sort: [
            { created: { order: "desc" }}
        ],
        size: 5,
        from: 0
      }),
      source_content_type: 'application/json'
    }
  });

  const dataValidationErrors = await axios.get(searchUrl, {
    auth: {
      username: GetAuthItem(authUser[0], authUser[1], authUser[2], authUser[3]),
      password: GetAuthItem(authPass[0], authPass[1], authPass[2], authPass[3])
    },
    params: {
      source: JSON.stringify({
        query: {
          bool: {
            must: [
              { match: { configId: data.id } },
              { match: { status: 3 } },
              { match: { step: 4 } }
            ]
          }
        },
        sort: [
            { created: { order: "desc" }}
        ],
        size: 10000,
        from: 0
      }),
      source_content_type: 'application/json'
    }
  });

  const dataOtherErrors = await axios.get(searchUrl, {
    auth: {
      username: GetAuthItem(authUser[0], authUser[1], authUser[2], authUser[3]),
      password: GetAuthItem(authPass[0], authPass[1], authPass[2], authPass[3])
    },
    params: {
      source: JSON.stringify({
        query: {
          bool: {
            must: [
              { match: { configId: data.id } },
              { match: { status: 3 } }
            ],
            must_not: [
              {match: { step: 4 } }
            ]
          }
        },
        sort: [
            { created: { order: "desc" }}
        ],
        size: 5,
        from: 0
      }),
      source_content_type: 'application/json'
    }
  });

  const dataImports = await axios.get(searchUrl, {
    auth: {
      username: GetAuthItem(authUser[0], authUser[1], authUser[2], authUser[3]),
      password: GetAuthItem(authPass[0], authPass[1], authPass[2], authPass[3])
    },
    params: {
      source: JSON.stringify({
        query: {
          bool: {
            must: [
              { match: { configId: data.id } },
              { match: { status: 1 } },
              { match: { step: 5 } }
            ]
          }
        },
        sort: [
            { created: { order: "desc" }}
        ],
        size: 10000,
        from: 0
      }),
      source_content_type: 'application/json'
    }
  });


  const dataTablesMerged = Object.assign({}, data, {
    logInfoTbl: dataLoginInfo.data.hits.hits, 
    otherErrorsTbl: dataOtherErrors.data.hits.hits, 
    validationErrorsTbl: dataValidationErrors.data.hits.hits,
    importsTbl: dataImports.data.hits.hits });

  return dataTablesMerged;
}